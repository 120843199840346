import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import sortBy from 'lodash.sortby';
import { Container } from 'react-bootstrap';
import classNames from "classnames"

import bodyRenderer from 'gatsby-theme-nurofen/src/utils/bodyRenderer';
import { formatAnchor } from 'utils/helpers';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { parseBanner } from 'components/Banner/parsers';
import Layout from 'components/Layout';
import SymptomsIllustrations from 'components/SymptomsIllustrations';
import TwoColumnSymptomsIllustrations from 'components/TwoColumnSymptomsIllustrations';
import FeaturedArticles from 'components/FeaturedArticles';
import FeaturedProducts from 'components/FeaturedProducts';
import Banner from 'components/Banner';
import FaqBanner from 'components/FaqBanner';
import Video from 'components/common/Video';
import CardsWithImages from 'components/CardsWithImages';
import PromoBanner from 'components/PromoBanner';
import InteractiveImage from 'components/InteractiveImage';
import RelatedProducts from 'components/RelatedProducts';
import BannerColor from 'components/BannerColor';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import SymptomsFaq from 'components/SymptomsFaq';
import AdvantagesList from 'components/AdvantagesList';
import ReadReportSection from 'components/ReadReportSection';
import parseCampaignBody from 'gatsby-theme-nurofen/src/templates/CampaignPage/parsers';

import './CampaignPage.scss';

interface CampaignPageProps extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[]; featuredProductsLinks: string[] };
  data: CampaignPageTypes.ICampaignPageIncomeData;
}

const elements: AppPageDataTypes.TBodyElements = {
  'Featured Articles': (props, keyId) => (
    <FeaturedArticles
      key={keyId}
      bgColor={props.featuredArticlesBgColor}
      title={props.featuredArticlesSectionTitle}
      subtitle={props.featuredArticlesSectionSubtitle}
      btn={
        props.featuredArticlesCTAButtonData?.length
          ? parseNFButton(props.featuredArticlesCTAButtonData[0].properties)
          : undefined
      }
      articles={props.featuredArticlesData}
      mode={props.featuredArticlesSelectedArticleMode}
      articlesByTag={props.featuredArticlesByTag?.nodes}
      carouselControls={props.carouselControls}
      featuredArticlesAnchor={props.featuredArticlesAnchor}
    />
  ),
  'Featured Products': (props, keyId) => {
    return props.typeOfProductCarousel === 'featured' ? (
      <FeaturedProducts
        key={keyId}
        title={props.featuredProductsSectionTitle}
        subtitle={props.featuredProductsSectionSubtitle}
        sectionBtn={
          props.featuredProductsCTASectionButtonData?.length
            ? parseNFButton(props.featuredProductsCTASectionButtonData[0].properties)
            : undefined
        }
        bgColor={props.featuredProductsBgColor}
        enableProductsCTA={props.featuredProductsEnableProductsCTA}
        enableProductsSecondaryText={props.featuredProductsEnableProductsSecondaryText}
        responsiveCarouselStructure={props.featuredProductsResponsiveCarouselStructure}
        products={sortBy(props.featuredProducts?.nodes, (item) =>
          props.featuredProductsLinks.indexOf(item.link)
        )}
        cardBtnData={
          props.featuredProductsCTACardButtonData?.length
            ? {
                text: props.featuredProductsCTACardButtonData[0].properties.text,
                ariaLabel: props.featuredProductsCTACardButtonData[0].properties.ariaLabel,
                btnStyles: props.featuredProductsCTACardButtonData[0].properties.btnStyles,
                icon: props.featuredProductsCTACardButtonIcon,
              }
            : undefined
        }
        carouselControls={props.carouselControls}
        featuredProductsAnchor={props.featuredProductsAnchor}
        listingName={props.listingName}
        displayAlternativeProductDescription={
          props.featuredProductsDisplayAlternativeProductDescription
        }
      />
    ) : props.typeOfProductCarousel === 'related' ? (
      <RelatedProducts
        title={props.featuredProductsSectionTitle}
        showBtn={
          props.featuredProductsEnableProductsCTA === '1'
            ? true
            : props.featuredProductsEnableProductsCTA === '0'
            ? false
            : undefined
        }
        btn={
          props.featuredProductsCTACardButtonData?.length
            ? parseNFButton(props.featuredProductsCTACardButtonData[0].properties)
            : undefined
        }
        btnProduct={
          props.productButton?.length ? parseNFButton(props.productButton[0].properties) : undefined
        }
        centerCards
        products={props.featuredProducts.nodes}
        productsLinks={props.featuredProducts.nodes.map(({ link }) => link)}
        carouselControls={props.carouselControls}
        listingName={props.listingName}
        displayAlternativeProductDescription={
          props.featuredProductsDisplayAlternativeProductDescription
        }
      />
    ) : null
  },
  'Video Block': (props, keyId) => (
    <div
      className="campaign-anchor-section"
      {...(props.videoBlockAnchor ? { id: props.videoBlockAnchor } : {})}
    >
      <Container fluid>
        <Video
          key={keyId}
          btnPlay={props.btnPlay.svg.content}
          previewImg={props.previewImg[0].properties}
          video={props.videoLink}
          videoTitle={props?.videoTitle}
          videoDescription={props?.videoDescription}
          btn={
            props?.videoCTABtn?.length ? parseNFButton(props?.videoCTABtn[0].properties) : undefined
          }
        />
      </Container>
    </div>
  ),
  'Cards with images': (props, keyId) => (
    <CardsWithImages
      key={keyId}
      infoCardsTitle={props.infoCardsTitle}
      infoCardsList={props.infoCardsList}
      infoCardsAnchor={props.infoCardsAnchor}
    />
  ),
  'Promo banner': (props, keyId) => (
    <PromoBanner
      key={keyId}
      title={props.promoBannerTitle}
      promoItems={props.promoBannerCards}
      btn={parseNFButton(props.promoBannerButton[0].properties)}
      promoImg={props.promoBannerImage}
      promoImgAlt={props.promoBannerImageAlt}
      bgColor={props.promoBannerBgColor.label}
      promoBannerAnchor={props.promoBannerAnchor}
      promoAdditionalInfo={props.promoAdditionalInfo}
    />
  ),
  'Interactive image': (props, keyId) => (
    <InteractiveImage
      key={keyId}
      title={props.interactiveImageTitle}
      img={props.interactiveImage}
      interactiveTabs={props.interactiveImageSymptomCards}
      openedIndex={props.openedIndex}
      imgType={props.interactiveImageType}
      bgColor={props.interactiveImageBgColor?.label}
      interactiveImageAnchor={props.interactiveImageAnchor}
      alt={props.interactiveImageAlt}
    />
  ),
  'Symptoms List': (props, keyId) => (
    <SymptomsIllustrations
      key={keyId}
      illustrations={props.illustrations}
      headingGroup={props?.headingGroup}
      symptomsAnchor={props?.symptomsAnchor}
    />
  ),
  'FAQ Banner': (props, keyId) => (
    <FaqBanner
      key={keyId}
      accordionTitle={props.accordionTitle}
      dosageTitle={props?.dosageTitle}
      img={props?.img}
      imageType={props.imageType}
      imagePosition={props.imagePosition}
      isImageHiddenOnMobile={props.isImageHiddenOnMobile}
      imageAlt={props?.imageAlt}
      bgBannerColor={props?.bgBannerColor}
      btn={props?.btn}
      accordionItems={props?.accordionItems}
      downloadBtnList={props?.downloadBtnList}
      downloadIcon={props?.downloadIcon}
      downloadIconText={props?.downloadIconText}
      downloadIconAlt={props?.downloadIconAlt}
      faqBannerAnchor={props?.faqBannerAnchor}
      sectionLandmark={props?.sectionLandmark}
    />
  ),
  'Two Column Symptoms List': (props, keyId) => (
    <TwoColumnSymptomsIllustrations
      key={keyId}
      symptomsSection={props.symptomsSection}
      symptomsSectionTitle={props?.symptomsSectionTitle}
      symptomsSectionBgColor={props?.symptomsSectionBgColor}
    />
  ),
  'Text Item': (props, keyId) => (
    <Container fluid>
      <DangerouslySetInnerHtml key={keyId} html={props.text} />
    </Container>
  ),
  'Color Banner': (props, keyId) => (
    <BannerColor
      key={keyId}
      title={props?.title}
      text={props?.text}
      pageBannerButtonData={props?.pageBannerButtonData}
      colorImage={props?.colorImage}
      colorImageMobile={props?.colorImageMobile}
      imageAlt={props?.imageAlt}
      bannerBackground={props?.bannerBackground}
      bannerSize={props.bannerSize}
      phoneImagePosition={props.phoneImagePosition}
      desktopImagePosition={props.desktopImagePosition}
      labelText={props?.labelText}
      labelBackground={props?.labelBackground}
      colorText={props.colorText}
    />
  ),
  'Symptoms FAQ': ({ symptomsFAQ, accordionWrapperHeaderClasses }, keyId) => (
    <SymptomsFaq
      key={keyId}
      symptomsFAQ={symptomsFAQ}
      wrapperHeaderClassNames={accordionWrapperHeaderClasses}
    />
  ),
  'Advantages List': (props, keyId) => (
    <AdvantagesList key={keyId} seoTitle={props?.seoTitle} advantagesItem={props?.advantagesItem} />
  ),
  "Read Report Section": (
    {
      title,
      rteTitle,
      description,
      sectionAnchor,
      image,
      imageAlt,
      imageDescription,
      imageBackground,
      anchorText,
      link,
      fileLink,
      icon,
      sectionBackground,
      sectionTheme,
      note,
      words,
      thingsList,
    },
    keyId
  ) => (
    <ReadReportSection
      key={keyId}
      title={title}
      rteTitle={rteTitle}
      description={description}
      sectionAnchor={formatAnchor(sectionAnchor)}
      image={image}
      imageAlt={imageAlt}
      imageDescription={imageDescription}
      imageBackground={imageBackground}
      anchorText={anchorText}
      link={link}
      fileLink={fileLink}
      icon={icon}
      sectionTheme={sectionTheme}
      sectionBackground={sectionBackground}
      note={note}
      words={words}
      thingsList={thingsList}
    />
  ),
};

const CampaignPage: FC<CampaignPageProps> = ({
  data,
  pageContext: { breadcrumbs, featuredProductsLinks },
}) => {
  const {
    allCampaignPage: {
      nodes: [
        {
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          ogImage,
          lang,
          campaignBanner,
          campaignHeader,
          campaignBody,
          typeOfProductCarousel,
          pageName,
        },
      ],
    },
    featuredArticlesByTag,
    featuredProducts,
    carouselControls,
    siteSettings,
    header,
    footer,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  } = data;
  const parsedBody = parseCampaignBody(campaignBody);
  
  const isZavanceMiniPage = pageName?.includes('Nurofen Zavance Mini Liquid Capsules');

  const classes = classNames("campaign-page-holder", {
    "zavance-mini": isZavanceMiniPage,
  })

  const campaignBodyContent = bodyRenderer(parsedBody, elements, {
    featuredArticlesByTag,
    featuredProducts,
    carouselControls,
    featuredProductsLinks,
    typeOfProductCarousel,
    listingName: pageName,
  });

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      }}
      siteSettings={siteSettings}
      header={header}
      headerCustom={campaignHeader?.[0]?.properties}
      footer={footer}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      className="home-page"
    >
      <div className={classes}>
        {campaignBanner?.length ? (
          <Banner {...parseBanner(campaignBanner[0].properties)} breadcrumbs={breadcrumbs} />
        ) : null}
        {campaignBodyContent}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $link: String!
    $articleTagId: Int
    $featuredProductsLinks: [String]
    $pageBannerImageSize: Int = 1200
    $pageIdRegex: String
  ) {
    featuredArticlesByTag: allUmbracoArticles(
      filter: {
        tags: { elemMatch: { id: { eq: $articleTagId } } }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    featuredProducts: allUmbracoProduct(
      filter: { link: { in: $featuredProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    allCampaignPage(filter: { lang: { eq: $lang }, link: { eq: $link } }) {
      nodes {
        ...FragmentSeo
        lang
        pageName
        campaignBanner {
          properties {
            ...FragmentBannerAU
          }
        }
        campaignBody {
          structure
          properties {
            ...FragmentFeaturedArticles
            ...FragmentFeaturedProducts
            ...FragmentVideoBlock
            ...FragmentPromoBanner
            ...FragmentInteractiveImage
            ...FragmentInfoCards
            ...FragmentSymptomsIllustrations
            ...FragmentFAQBanner
            ...FragmentTwoColumnSymptomsIllustrations
            ...FragmentReadReportAU
            text
          }
        }
        typeOfProductCarousel
        campaignHeader {
          properties {
            ...FragmentHeaderCustom
          }
        }
      }
    }
    pageLangs: allCampaignPage(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`

export default CampaignPage
